// Variables
@import "variables";
form.form-inline {
    display:inline-block;
}
.mini-navbar {
    .logo-element {
        padding:10px;

        img {
            max-width:100%;
        }
    }
}

.primary-contact {
    border-color:#1ab394;
}
.navbar-static-top {
    min-height:60px;
}
.navbar-form-custom {
    width:500px;
}
.easy-autocomplete {
    position: relative;
    &.eac-inspinia {
        ul {
            li {}
        }
    }
    input {
        padding:0 1em;
    }
    input:hover,
    input:focus {
        box-shadow:none;
    }
    a { display: block; }
}


.easy-autocomplete.eac-bootstrap input {
    border-color: #ccc;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: #555;
}

.easy-autocomplete-container {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
}
.easy-autocomplete-container ul {
    background: none repeat scroll 0 0 #ffffff;
    border-top: 1px dotted #ccc;
    display: none;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    position: relative;
    top: -1px;
}
.easy-autocomplete-container ul li, .easy-autocomplete-container ul .eac-category {
    background: inherit;
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    padding: 4px 12px;
}
.easy-autocomplete-container ul li:last-child {
    border-radius: 0 0 2px 2px;
    border-width: 0 1px 1px;
}
.easy-autocomplete-container ul li.selected {
    background: none repeat scroll 0 0 #ebebeb;
    cursor: pointer;
}
.easy-autocomplete-container ul li.selected div {
    font-weight: normal;
}
.easy-autocomplete-container ul li div {
    display: block;
    font-weight: normal;
    word-break: break-all;
}
.easy-autocomplete-container ul li b {
    font-weight: bold;
}
.easy-autocomplete-container ul .eac-category {
    font-color: #aaa;
    font-style: italic;
}

.eac-description .eac-item span {
    color: #aaa;
    font-style: italic;
    font-size: 0.9em;
}

.eac-icon-left .eac-item img {
    margin-right: 4px;
    max-height: 30px;
}

.eac-icon-right .eac-item {
    margin-top: 8px;
    min-height: 24px;
    position: relative;
}
.eac-icon-right .eac-item img {
    margin-left: 4px;
    max-height: 30px;
    position: absolute;
    right: -4px;
    top: -8px;
}